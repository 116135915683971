import React from "react";
import "../styles/App.css";

function Privacy() {
  return (
    <div className="container">
      <h1>Privacy Policy</h1>
      <p>
        Last updated <strong> August 17, 2023 </strong>
      </p>
      <ul className="list">
        <li>We don't sell or share your data.</li>
        <li>
          To delete your data, click the "Delete Account" button at the base of
          the "Settings" page.
        </li>
      </ul>
    </div>
  );
}

export default Privacy;
