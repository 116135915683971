// Contact.js
import React from "react";
import "../styles/App.css";

function NotFound() {
  return (
    <div className="container">
     <h1>We're Sorry</h1>
      <p className="description">That page doesn't exist</p>
         
    </div>
  );
}

export default NotFound;
