import React, { useEffect } from 'react';


function AppStore() {
    useEffect(() => {
        window.location.href = "https://apps.apple.com/app/id6449982664";
    }, []);

    return null;
}

export default AppStore;