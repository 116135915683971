import React from "react";
import { Link } from "react-router-dom";
import styles from "../styles/NavBar.module.css";

function NavBar() {
  return (
    <nav className={styles.navBar}>
      <Link to="/">
        <div className={styles.box} style={{ fontSize: 18 }}>
          Tap
        </div>
      </Link>
      <ul className={styles.navList}>
        <li>
          <Link to="/privacy">Privacy Policy</Link>
        </li>
        <li>
          <Link to="/terms">Terms of Use</Link>
        </li>
        <li>
          <Link to="/contest">Contest Rules</Link>
        </li>
      </ul>
    </nav>
  );
}

export default NavBar;
