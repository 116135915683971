import React from "react";
import "../styles/App.css";

function Contest() {
  return (
    <div className="container">
      <h1>Contest Rules</h1>
      <p>
        Last updated <strong> August 23, 2023 </strong>
      </p>
      {/* <div> */}
        {/* <h3>SecondTap Contest Rules</h3> */}

        <h3>1. Eligibility</h3>
        <ul class="list">
          <li>
            The SecondTap contest is open to all users of the app who are 12
            years of age or older at the time of entry.
          </li>
        </ul>

        <h3>2. Contest Duration</h3>
        <ul class="list">
          <li>
            The contest repeats daily, as detailed within
            the app. Entries after the specified timeframe will be
            considered for the next contest.
          </li>
        </ul>

        <h3>3. How to Enter</h3>
        <ul class="list">
          <li>
            Participants are automatically entered into the contest by playing the daily numbers game.
          </li>
        </ul>

        <h3>4. Prize Determination</h3>
        <ul class="list">
          <li>
            Winners will be selected based on the lowest discrepancy in their
            tap timing for the number drawn at midnight EST.
          </li>
          <li>
            Winners will be notified within the app, and their prize will be automatically transferred to their account.
          </li>
        </ul>

        <h3>5. Prizes</h3>
        <ul class="list">
          <li>
            Prizes are as specified within the app. All prizes are
            non-transferrable and cannot be exchanged for cash or any other
            items.
          </li>
        </ul>

        <h3>6. General Conditions</h3>
        <ul class="list">
          <li>
            By participating, contestants agree to abide by and be bound by
            these official rules and the decisions of SecondTap, which are final
            in all matters related to the contest.
          </li>
          <li>
            SecondTap reserves the right to modify or cancel the contest in the
            event of any unforeseen issues.
          </li>
        </ul>

        <h3>7. Release and Limitations of Liability</h3>
        <ul class="list">
          <li>
            By participating, contestants release SecondTap and its associated
            entities from any and all liability for any injuries, loss, or
            damage of any kind arising from or in connection with the contest.
          </li>
        </ul>

        <h3>8. Data & Privacy</h3>
        <ul class="list">
          <li>
            Personal data submitted by contestants will be used solely in
            accordance with current data protection regulations and SecondTap's
            privacy policy.
          </li>
        </ul>

        <h3>9. Apple Disclaimer</h3>
        <ul class="list">
          <li>
            Apple is not a sponsor nor is involved in any way with this contest,
            sweepstakes or giveaway.
          </li>
          <li>Apple does not endorse this contest in any way.</li>
          <li>
            Any questions, comments, or complaints regarding the contest must be
            directed to SecondTap and not Apple.
          </li>
        </ul>

        <h3>10. Contact</h3>
        <p>
          For any queries regarding the contest, please contact us at <a href="mailto:contact@secondtap.app"> contact@secondtap.app</a>
        </p>
      {/* </div> */}
    </div>
    
  );
}

export default Contest;
