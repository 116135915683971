// HomePage.js
import React from "react";
import "../styles/App.css";
import AppStore from "../assets/app.svg";

import Logo from "../components/Logo";

function Home() {
  return (
    <div className="container">
      <Logo fontSize={24} />
      <p className="description">Tap as close to the second as you can.</p>
      <div className="form">
        <a href="https://apps.apple.com/app/id6449982664">
          <img src={AppStore} alt="app store" className="appImg" />
        </a>
      </div>
    </div>
  );
}

export default Home;
