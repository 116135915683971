import React from "react";
import styles from "../styles/Logo.module.css";

function Logo({ fontSize }) {
  return (
    <div className={styles.logoContainer}>
      <p className={styles.text} style={{ fontSize: fontSize }}>Second</p>
      <div className={styles.box} style={{ fontSize: fontSize }}>Tap</div>
    </div>
  );
}

export default Logo;