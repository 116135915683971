// Contact.js
import React from "react";
import "../styles/App.css";

function Contact() {
  return (
    <div className="container">
     <h1>Contact Us</h1>
      <p className="description"> Questions or comments, send us an email at 
          <a href="mailto:contact@secondtap.app"> contact@secondtap.app</a></p>
    </div>
  );
}

export default Contact;
