import React from "react";
import "../styles/App.css";

function Terms() {
  return (
    // <div>
      <div className="container">
        <h1>Terms of Use</h1>
        <p>
          Last updated <strong> August 17, 2023 </strong>
        </p>
      {/* </div> */}
      <p>
        These terms and conditions outline the rules and regulations for the use
        of SecondTap.
      </p>

      <p>
        By using this App we assume you accept these terms and conditions. Do
        not continue to use SecondTap if you do not agree to take all of the terms
        and conditions stated on this page.
      </p>

      <p>
        The following terminology applies to these Terms and Conditions, Privacy
        Statement and Disclaimer Notice and all Agreements: "Client", "You" and
        "Your" refers to you, the person log on this website and compliant to
        the Company’s terms and conditions. "The Company", "Ourselves", "We",
        "Our" and "Us", refers to our Company. "Party", "Parties", or "Us",
        refers to both the Client and ourselves. Any use of the above
        terminology or other words in the singular, plural, capitalization
        and/or he/she or they, are taken as interchangeable and therefore as
        referring to same.
      </p>

      <h3>
        <strong>License Agreement</strong>
      </h3>
      <ol className="list">
        <li>
          This license will also govern any updates of the Licensed Application
          provided by Licensor that replace, repair, and/or supplement the first
          Licensed Application, unless a separate license is provided for such
          update, in which case the terms of that new license will govern.
        </li>
        <li>
          You may not reverse engineer, translate, disassemble, integrate,
          decompile, remove, modify, combine, create derivative works or updates
          of, adapt, or attempt to derive the source code of the Licensed
          Application, or any part thereof (except with weWake LLC's prior
          written consent).
        </li>

        <li>
          You may not copy (excluding when expressly authorized by this license
          and the Usage Rules) or alter the Licensed Application or portions
          thereof. You may create and store copies only on devices that You own
          or control for backup keeping under the terms of this license, the
          Usage Rules, and any other terms and conditions that apply to the
          device or software used. You may not remove any intellectual property
          notices. You acknowledge that no unauthorized third parties may gain
          access to these copies at any time. If you sell your Devices to a
          third party, you must remove the Licensed Application from the Devices
          before doing so. The Licensor is solely responsible for providing any
          maintenance and support services for this Licensed Application. You
          can reach the Licensor at the email address listed in the App Store
        </li>

        <li>
          You acknowledge that Licensor will be able to access and adjust Your
          downloaded Licensed Application content and Your personal information,
          and that Licensor's use of such material and information is subject to
          Your legal agreements with Licensor and Licensor's privacy policy:
        </li>
        <li>
          You acknowledge that the Licensor may periodically collect and use
          technical data and related information about your device, system, and
          application software, and peripherals, offer product support,
          facilitate the software updates, and for purposes of providing other
          services to you (if any) related to the Licensed Application. Licensor
          may also use this information to improve its products or to provide
          services or technologies to you, as long as it is in a form that does
          not personally identify you.
        </li>

        <li>
          Licensor warrants that the Licensed Application is free of spyware,
          trojan horses, viruses, or any other malware at the time of Your
          download. Licensor warrants that the Licensed Application works as
          described in the user documentation.
        </li>

        <li>
          The license is valid until terminated by weWake LLC or by You. Your
          rights under this license will terminate automatically and without
          notice from weWake LLC if You fail to adhere to any term(s) of this
          license. Upon License termination, You shall stop all use of the
          Licensed Application, and destroy all copies, full or partial, of the
          Licensed Application.
        </li>

        <li>
          This License Agreement is governed by the laws of the Commonwealth of
          Massachusetts excluding its conflicts of law If any of the terms of
          this agreement should be or become invalid, the validity of the
          remaining provisions shall not be affected. Invalid terms will be
          replaced by valid ones formulated in a way that will achieve the
          primary purpose.
        </li>
      </ol>
    </div>
  );
}

export default Terms;
