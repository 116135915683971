import React from "react";
import styles from "../styles/Footer.module.css";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div className={styles.footerList}>
        <Link to="/contact">Contact</Link>
        <p>Copyright &copy; 2023 SecondTap</p>
    </div>
  );
}

export default Footer;
