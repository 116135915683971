import './styles/App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Privacy from './pages/Privacy';
import Terms from './pages/Terms';
import Home from './pages/Home';
import NavBar from './components/NavBar';
import Footer from './components/Footer';
import Contact from './pages/Contact';
import AppStore from './components/AppStore';
import NotFound from './pages/NotFound';
import Contest from './pages/Contest';

function App() {
  return (
    <Router>
        <NavBar />
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/contest" element={<Contest />} />
            <Route path="/join" element={<AppStore />} />
            <Route path="*" element={<NotFound />} />     
        </Routes>
        <Footer />
    </Router>
);
}

export default App;
